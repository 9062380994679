import React from 'react';
import { graphql } from 'gatsby';

// @ts-ignore
import { window } from 'browser-monads';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not Found" />
      <div className="not-found">
        <h1>error 404</h1>
        <p>page not found</p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
